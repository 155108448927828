.barContainer {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 1350px) {
  .barContainer {
    flex-direction: column;
  }
}

.filterContainer {
  justify-content: flex-start;
}

.buttonOptions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.popupContentContainer {
  display: flex;
  flex-direction: column;
}
