.textField {
  padding-bottom: 40px;
}

.textField :global .MuiSelect-select {
  font-size: 14px;
}

.textField :global .MuiMenuItem-root {
  font-size: 10px;
}
.textField :global .MuiButtonBase-root {
  font-size: 10px;
}

.textField :global .MuiButtonBase-root .MuiMenuItem-root .MuiMenuItem-gutters .MuiMenuItem-root .MuiMenuItem-gutters {
  font-size: 10px;
}
