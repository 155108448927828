.filterContainer {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}

.selectContainer {
  width: 200px;
}

.selectOptions {
  width: 200px;
  padding-left: 16px;
}

.formInputContainer {
  width: 300px;
  position: relative;
  padding-left: 16px;
}

.errorContainer {
  padding-left: 16px;
}

.dateTimeMargin {
  margin: 0 10px;
}

.searchButtonMargin {
  display: flex;
  align-items: center;
}

.progressBarColor {
  background-color: #4faee0;
}

.tableHeaderCell {
  padding-left: 32px;
}

.tableContainer {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.importAreaContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 32px;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.paginationContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.uploadOptionsContainer {
  display: flex;
  justify-content: flex-end;
}

.topBarContainer {
  display: flex;
  justify-content: space-between;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
